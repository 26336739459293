import configFile from "@/config.json";

export function useConfig() {
  const hostname = window.location.hostname;

  if (!configFile[hostname]) {
    throw new Error(`No configuration for the requested hostname ${hostname}`);
  }

  function getApiUrl() {
    if (hostname === "localhost" && import.meta.env.VITE_API_URL) {
      return import.meta.env.VITE_API_URL;
    }
    const hostConfig = configFile[hostname];
    return hostConfig.api;
  }

  return {
    getApiUrl,
  };
}
