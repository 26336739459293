// noinspection AllyPlainJsInspection

import {resetApp, useLogout} from "@/view/authentication/useLogout";
import {setRefreshToken} from "@/config/api/setRefreshToken";
import useAuth from "@/view/authentication/useAuth";
import {message} from 'ant-design-vue';

const TOKEN_LOGIN_URL = "/auth/login";
const TOKEN_REFRESH_URL = "/auth/refresh";
const TOKEN_VALIDATE = "/auth/validate/";
const TOKEN_RESET_PASSWORD = "auth/reset-password";

export function useRefreshTokenInterceptor({request}) {
    const {logout} = useLogout();
    const {getToken} = useAuth();
    let isRefreshing = false;
    const state = {
        failedQueue: [],
    };

    async function startRefresh() {
        if (isRefreshing) {
            return new Promise((resolve, reject) => {
                state.failedQueue.push({resolve, reject});
            });
        }

        function refreshSuccess() {
            console.log('refresh success', getToken)
            processQueue();
            isRefreshing = false;
            return getToken;
        }

        function refreshFailed(error) {
            console.log('refresh failed', Object.keys(error))
            processQueue(error);
            isRefreshing = false;
            if (error?.response?.status === 401) {
                logout();
            }
            throw error;
        }

        isRefreshing = true;

        try {
            await setRefreshToken();
        } catch (error) {
            return refreshFailed(error);
        }
        return refreshSuccess();
    }

    async function interceptor(failedRequest) {
        const originalRequest = failedRequest.config;
        // eslint-disable-next-line
        if ([TOKEN_REFRESH_URL, TOKEN_LOGIN_URL, TOKEN_RESET_PASSWORD, TOKEN_VALIDATE].includes(originalRequest.url) || originalRequest.url.includes(TOKEN_VALIDATE)) {
            throw failedRequest;
        }
        console.log(failedRequest.response.status)

        if (failedRequest.response.status !== 401) {
            throw failedRequest;
        }

        if (originalRequest.retry) {
            throw failedRequest;
        }

        originalRequest.retry = true;
        return startRefresh().then((t) => {
            originalRequest.headers.Authorization = `Bearer ${getToken}`;
            // console.log('refresh again', t)
            return request(originalRequest);
        });
    }

    function processQueue(error = null) {
        state.failedQueue.forEach((promise) => {
            if (error) promise.reject(error);
            promise.resolve(getToken);
        });
    }

    return {
        interceptor,
        startRefresh,
    };
}
