import axios from "axios";
import {useSessionAuth} from "@/pinia/AuthSessionStore";
import {useRefreshTokenInterceptor} from "@/config/api/useRefreshTokenInterceptor";
import {useConfig} from "@/useConfig";
const { getApiUrl } = useConfig();
const api = axios.create({
    baseURL: getApiUrl(),
    headers: {
        "Content-Type": "application/json",
    },
});

api.interceptors.request.use(async (config) => {
    const { token } = useSessionAuth();
    if (token && config.url !== "/auth/refresh") {
        config.headers.Authorization = "Bearer " + token;
    }
    return config;
});

api.interceptors.response.use(
    (response) => response,
    (failedRequest) => {
        const { interceptor } = useRefreshTokenInterceptor({ ...api });
        return interceptor(failedRequest);
    }
);

export default api;
