// The "pseudocode" for the built-in Error class defined by JavaScript itself
export default class ExpiredRefreshTokenError extends Error {
    constructor() {
        const message = "request.expired_refresh_token";
        super(message);
        this.message = message;
        this.name = "ExpiredRefreshTokenError"; // (different names for different built-in error classes)
        this.response = {status: 401};
        this.stack = Error.captureStackTrace(this, ExpiredRefreshTokenError); // non-standard, but most environments support it
    }
}
